import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user_email:'',
    getCurrentUser:{},
  },
  getters: {
    get_user_email: (state) => {
      return state.user_email;
    },
    get_current_user_email: (state) => {
      return state.getCurrentUser;
    },
  },
  mutations: {
    SET_USEREMAIL(state, user_email) {
      state.user_email = user_email;
    },
    SET_CURRENT_USER_DETAILS(state, getCurrentUser) {
      state.getCurrentUser = getCurrentUser;
    },
  },
  plugins: [createPersistedState()],
  actions: {
  },
  modules: {
  }
})
