import Vue from "vue";
import VueRouter from "vue-router";
import LoginPage from "../views/MainPages/LoginPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/LandingPage",
    name: "LandingPage",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/MainPages/LandingPage.vue"
      ),
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/Dashboard.vue"
          ),
      },
      {
        path: "/Agencies",
        name: "Agencies",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/Agencies.vue"
          ),
      },
      {
        path: "/Travelogs",
        name: "Travelogs",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/Travelogs.vue"
          ),
      },

      {
        path: "/app_users",
        name: "App Users",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/AppUsers.vue"
          ),
      },
      {
        path: "/bms_users",
        name: "BMS Users",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/Bmsusers.vue"
          ),
      },

      {
        path: "/pov",
        name: "POVs",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/Povs.vue"
          ),
      },

      {
        path: "/restaurants",
        name: "Restaurants",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/Restaurants.vue"
          ),
      },

      {
        path: "/sherpas",
        name: "Sherpas",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/Sherpas.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   const CURRENT_USER = this.$store.getters.get_current_user_email;
//   // Check if the length of CURRENT_USER is 0
//   if (CURRENT_USER.length === 0) {
//     // Redirect to the login page if the user is trying to access '/agencies'
//     if (to.path === "/Agencies") {
//       next("/"); // Redirect to the login page
//     } else {
//       next(); // Continue navigation
//     }
//   } else {
//     next(); // Continue navigation
//   }
// });

export default router;
