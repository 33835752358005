import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import { Amplify } from "aws-amplify";
import * as VueGoogleMaps from "vue2-google-maps";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBUp8E7OghfEFg9fJ1ePokoOhE1I8MaD-M",
    libraries: "places",
  },
});

Amplify.configure({
  Auth: {
    userPoolId: "ap-south-1_BJXF5MAIK",
    region: "ap-south-1",
    userPoolWebClientId: "12v763j97s59375js4kdi74ml0",
  },
  API: {
    aws_project_region: "ap-south-1",
    aws_appsync_graphqlEndpoint: "https://x6fz7nxbsjgarkzbxwsj7wj3ra.appsync-api.ap-south-1.amazonaws.com/graphql",
    aws_appsync_region: "ap-south-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
  },
});

Vue.config.productionTip = false

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
