import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        BlueVariant1: '#23e2dd',
        
        RedVariant1:'#e71d4f',
      },
    },
  },
});
