<template>
  <v-app>
      <router-view/>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style>
.MaxWidthVariant{
  max-width: 250px !important;
}


.MaxWidthVariant2{
  max-width: 200px !important;
}
.CardGradient {
  background: linear-gradient(to right, #33bbc550, #ecbfce98);
}
.GradientColor{
  background-image: linear-gradient(#a7f3f1, #f5a4b8) !important;
}
.FontSize12px{
  font-size: 12px !important;
}
.FontSize14px{
  font-size: 14px !important;
}
.FontSize16px{
  font-size: 16px !important;
}
.FontSize18px{
  font-size: 18px !important;
}
.FontSize22px{
  font-size: 22px !important;
}
.FontSize25px{
  font-size: 25px !important;
}
.FontSize30px{
  font-size: 30px !important;
}
.tableClassVariant1 table {
  border-collapse: separate;
  border-spacing: 0 0px !important;
  width: 100% !important;
}
.tableClassVariant1 th {
  position: -webkit-sticky !important;
  top: 0 !important;
  position: sticky !important;
  z-index: 1 !important;
  background-image: linear-gradient(#f6faff, #23e2dd);
  color: #000000 !important;
  height: 24px !important;
  border: 1px solid #000000;
  border-bottom: 1px solid #000000 !important;
  padding: 5px;
  font-size: 12px !important;
}
.tableClassVariant1 td {
  height: 24px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
}
.tableClassVariant1 tr:nth-child(even) {
  background-color: #e71d502d  !important;
}
</style>