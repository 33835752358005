<template>
  <div class="overflow-y-hidden">
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <div id="particles-js"></div>
    <div class="overlay-container">
      <div class="card">
        <v-row>
          <v-col cols="12" align="center">
            <div class="flex-class d-flex justify-center">
              <div>
                <v-img
                  class="small-logo"
                  contain
                  max-width="80px"
                  src="@/assets/travelog-logo-small.png"
                />
              </div>
              <div>
                <v-img
                  class="ml-1 main-logo"
                  contain
                  max-width="130px"
                  src="@/assets/landscape-logo-dark.png"
                />
              </div>
            </div>
          </v-col>
        </v-row>
        <v-container fluid fill-height class="login-container">
          <v-layout>
            <v-flex>
              <v-card-text>
                <v-window v-model="loginStepper">
                  <v-window-item :value="1">
                    <v-form ref="EmailForm">
                      <div class="FontSize18px font-weight-bold text-left mb-2">
                        Email
                      </div>
                      <v-text-field
                        outlined
                        dense
                        class="FontSize12px"
                        :rules="[(v) => !!v || 'Email is required']"
                        @keydown.enter.prevent="ValidateMethod"
                        v-model="Login.Email"
                      ></v-text-field>
                    </v-form>
                    <v-card-actions>
                      <v-btn
                        block
                        class="text-capitalize login-button pa-5 ma-0"
                        dark
                        :loading="LoginPageLoading"
                        @click="ValidateMethod()"
                        >Login</v-btn
                      >
                    </v-card-actions>
                  </v-window-item>
                  <v-window-item :value="2">
                    <div class="FontSize16px text-left">
                      Kindly provide the OTP sent to your email for verification
                      purposes.
                    </div>
                    <v-card-text class="pa-0">
                      <v-form ref="OTPForm">
                        <div
                          class="FontSize18px font-weight-bold text-left mt-4 mb-2"
                        >
                          Enter OTP
                        </div>
                        <v-otp-input
                          :length="6"
                          color="green"
                          v-model="Login.Password"
                        ></v-otp-input>
                        <div class="font-weight-medium mt-2 mb-2" style="display: flex; justify-content: space-between;">
                          <div>
                            <v-btn
                              elevation="0"
                              outlined
                              rounded
                              @click="ValidateMethod(2)"
                              class="text-capitalize"
                            >
                              <v-icon size="18" color="red" class="mr-1"
                                >mdi-refresh</v-icon
                              >
                              Resend OTP
                            </v-btn>
                          </div>
                          <div class="d-flex justify-end">
                            <v-btn
                              elevation="0"
                              outlined
                              rounded
                              @click="loginStepper = 1"
                              class="text-capitalize"
                            >
                              <v-icon size="18" color="red" class="mr-1"
                                >mdi-arrow-left</v-icon
                              >
                              Re-enter your Email
                            </v-btn>
                          </div>
                        </div>
                      </v-form>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center">
                      <v-btn
                        block
                        dense
                        rounded
                        class="text-capitalize mt-5"
                        dark
                        :loading="OTPLoading"
                        @click="ValidateOTPMethod()"
                        >Verify</v-btn
                      >
                    </v-card-actions>
                  </v-window-item>
                </v-window>
              </v-card-text>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { LoginPageBgParticles } from "@/mixins/Extras/Particles.js";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  components: {
    Snackbar,
  },
  mixins: [LoginPageBgParticles],
  data: () => ({
    user: null,
    loginStepper: 1,
    LoginPageLoading: false,
    OTPLoading: false,
    SnackBarComponent: {},
    Login: {
      Email: "",
      Password: "",
    },
  }),
  mounted() {
    this.initParticles();
  },
  methods: {
    ValidateMethod(Toggle) {
      if (this.$refs.EmailForm.validate()) {
        this.GenerateOTPMethod();
        if (Toggle === 2) {
          this.$refs.OTPForm.reset();
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: "Please enter your email",
        };
      }
    },
    async GenerateOTPMethod() {
      try {
        this.LoginPageLoading = true;
        this.user = await Auth.signIn(this.Login.Email);

        if (this.user.challengeName === "CUSTOM_CHALLENGE") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            SnackbarText: "OTP Sent Successfully",
          };
          this.loginStepper = 2;
        }
        this.LoginPageLoading = false;
      } catch (error) {
        this.LoginPageLoading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: "Couldn't find your email",
        };
        console.log("Err", error);
      }
      // this.LoginPageLoading = true;
      // try {
      //   await Auth.signIn(this.Login.Email)
      //     .then((user) => {
      //       this.userDetails = user;
      //       console.log("PEN", this.userDetails)

      //       this.SnackBarComponent = {
      //         SnackbarVmodel: true,
      //         SnackbarColor: "green",
      //         SnackbarText: "OTP Sent Successfully",
      //       };
      //       this.loginStepper = 2;
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //       this.SnackBarComponent = {
      //         SnackbarVmodel: true,
      //         SnackbarColor: "red",
      //         SnackbarText: "Email does not exist",
      //       };

      //       this.LoginPageLoading = false;
      //     });
      // } catch (error) {
      //   this.SnackBarComponent = {
      //     SnackbarVmodel: true,
      //     SnackbarColor: "red",
      //     SnackbarText: error.message,
      //   };
      //   this.LoginPageLoading = false;
      // }
    },
    ValidateOTPMethod() {
      if (this.$refs.OTPForm.validate()) {
        this.VerifyOTPMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: "Please enter OTP",
        };
      }
    },
    async VerifyOTPMethod() {
      try {
        await Auth.sendCustomChallengeAnswer(this.user, this.Login.Password)
          .then(() => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              SnackbarText: "Verified Successfull",
            };
            this.$store.commit("SET_USEREMAIL", this.Login.Email);
            this.ActivateMethod();
          })
          .catch((error) => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              SnackbarText: error,
            };
          });
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: error.message,
        };
      }
      // // Collect the OTP entered by the user
      // const enteredOTP = this.Login.Password; // Assuming the entered OTP is stored in Login.Password

      // try {
      //   // Send the entered OTP to AWS Cognito for verification
      //   await Auth.sendCustomChallengeAnswer(this.userDetails, enteredOTP);

      //   // If verification is successful, proceed with authentication
      //   await Auth.currentAuthenticatedUser()
      //     .then((user) => {
      //       // Authentication successful, proceed to the next step
      //       this.$store.commit("SET_USEREMAIL", this.Login.Email);
      //       this.SnackBarComponent = {
      //         SnackbarVmodel: true,
      //         SnackbarColor: "green",
      //         SnackbarText: "OTP Verified Successfully",
      //       };
      //       this.ActivateMethod();
      //     })
      //     .catch((error) => {
      //       // Handle authentication error
      //       console.error(error);
      //       this.SnackBarComponent = {
      //         SnackbarVmodel: true,
      //         SnackbarColor: "red",
      //         SnackbarText: "Authentication failed",
      //       };
      //     });
      // } catch (error) {
      //   // Handle verification error
      //   console.error(error);
      //   this.SnackBarComponent = {
      //     SnackbarVmodel: true,
      //     SnackbarColor: "red",
      //     SnackbarText: "Verification failed",
      //   };
      // }
    },
    ActivateMethod() {
      this.$router.push("/LandingPage");
    },
  },
};
</script>

<style scoped>
.flex-class {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

#particles-js {
  background: black;
  background-size: cover !important;
  height: 100vh !important;
  width: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden !important;
}

.login-button {
  background: linear-gradient(to bottom, #33bbc5, #e91e63);
  color: white;
  border-radius: 20px;
  font-size: 16px;
  transition: background 0.3s ease;
}
.overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  background: white;
  padding: 20px;
  width: 500px !important;
  border-radius: 0px 80px 0 80px !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  text-align: center;
}

/* RESPONSE ADDED HERE  */
@media (max-width: 500px) {
  .login-container {
    padding: 0 !important;
  }

  .card {
    padding: 0 !important;
    border-radius: 0 !important;
    width: 300px !important;
  }

  .small-logo {
    margin-top: 5px;
    width: 50px;
  }
  .main-logo {
    margin-top: 5px;
    width: 70px;
  }
}
</style>
