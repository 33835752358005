<template>
  <v-snackbar
    v-model="SnackBarComponent.SnackbarVmodel"
    :color="SnackBarComponent.SnackbarColor"
    top
    :timeout="SnackBarComponent.timeout"
    class="snackbar-container"
  >
    <!-- <div class="flex-class"> -->
      <div class="text-center">{{ SnackBarComponent.SnackbarText }}</div>
      <!-- <div>
        <v-btn icon @click="closeSnackbar" class="position"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div> -->
    <!-- </div> -->
  </v-snackbar>
</template>
<script>
export default {
  props: {
    SnackBarComponent: Object,
  },

  methods: {
    closeSnackbar() {
      this.SnackBarComponent = false;
    },
  },
};
</script>

<!-- <style scoped>
.snackbar-container{
  position: absolute;
  top: 10px;
}
/* .flex-class {
  display: flex;
  align-items: center;
}
.position {
  position: absolute;
  bottom: 5px;
  right: 5px
} */
</style> -->
